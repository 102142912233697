import styled from "styled-components";
import { globalColors } from "../../styles";
import { Label, Image, CardContainer, ImageButton } from "../atoms";
import {
  dateString,
  kwhString,
  moneyString,
} from "../../services/dataFormatting";
export const RentListCard = ({ color, rent, onEdit, onDelete, onPrint }) => {
  return (
    <CardContainer open={true}>
      <LeftRentCardContainer>
        <Label color={globalColors.Gray}>{dateString(rent.date)}</Label>
        <ClockContainer className="hideOnMobile">
          <Label color={color}>{kwhString(rent.currentClock)}</Label>
        </ClockContainer>
        <Image
          className="hideOnMobile"
          src={"./GraterThen.svg"}
          height={"16px"}
          width={"16px"}
        ></Image>
        <ClockContainer className="hideOnMobile">
          <Label color={color}>{kwhString(rent.previousClock)}</Label>
        </ClockContainer>
      </LeftRentCardContainer>
      <RightRentCardContainer>
        <Label color={color}>{moneyString(rent.total)}</Label>
        <ButtonsContainer>
          <ImageButton
            backgroundImage={"./edit.svg"}
            onClick={() => onEdit(rent)}
          ></ImageButton>
          <ImageButton
            backgroundImage={"./delete.svg"}
            onClick={() => onDelete(rent)}
          ></ImageButton>
          <ImageButton
            backgroundImage={"./print.svg"}
            onClick={() => onPrint(rent)}
          ></ImageButton>
        </ButtonsContainer>
      </RightRentCardContainer>
    </CardContainer>
  );
};

const LeftRentCardContainer = styled.div`
  width: 50%;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const RightRentCardContainer = styled.div`
  width: 50%;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const ClockContainer = styled.div`
  margin: 0px 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;

  @media only screen and (max-width: 480px) {
    margin-left: 8px;
  }
`;
