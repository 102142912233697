import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { kwhString, moneyString } from "../../services/dataFormatting";

export const PrintRent = ({ rent }) => {
  return (
    <PDFViewer width={"100%"} height={"100%"}>
      <MyDocument rent={rent}></MyDocument>
    </PDFViewer>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
  },
  section: {
    marginLeft: 16,
  },
  header: {
    marginTop: 16,
    fontSize: "12px",
    color: "gray",
  },
  text: {
    marginTop: 4,
    fontSize: "14px",
  },
});

const MyDocument = ({ rent }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>
          {"Consumo do Mês = Relógio Atual - Relógio Anterior"}
        </Text>
        <Text style={styles.text}>
          {`Consumo do Mês = ${kwhString(rent.currentClock)} - ${kwhString(
            rent.previousClock
          )} = ${kwhString(rent.energyConsumption)}`}
        </Text>
        <Text style={styles.header}>
          {"Preço por Kwh = Valor Total / Consumo Total"}
        </Text>
        <Text style={styles.text}>
          {`Preço por Kwh = ${moneyString(rent.totalBill)} / ${kwhString(
            rent.totalConsumption
          )} = ${moneyString(rent.valueByKwh)} /Kwh`}
        </Text>
        <Text style={styles.header}>
          {"Energia = Consumo do Mês * Preço por Kwh"}
        </Text>
        <Text style={styles.text}>
          {`Preço por Kwh = ${kwhString(
            rent.energyConsumption
          )} * ${moneyString(rent.valueByKwh)} /Kwh = ${moneyString(
            rent.rentEnergyValue
          )}`}
        </Text>
        <Text style={styles.header}>
          {"Total = Valor Base + Energia + Água + IPTU"}
        </Text>
        <Text style={styles.text}>
          {`Total = ${moneyString(rent.base)} + ${moneyString(
            rent.rentEnergyValue
          )} + ${moneyString(rent.water)} + ${moneyString(
            rent.IPTU
          )} = ${moneyString(rent.total)}`}
        </Text>
      </View>
    </Page>
  </Document>
);
