import styled from "styled-components";
import { purpleColors } from "../../styles";
import { StartLogin } from "../organisms/startLogin";

export const LoginTemplate = () => {
  return (
    <LoginTemplateContainer>
      <StartLogin colors={purpleColors}></StartLogin>
    </LoginTemplateContainer>
  );
};

const LoginTemplateContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("./purpleBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
