import styled, { keyframes } from "styled-components";
import { globalColors } from "../../styles";

export const AddButton = ({ onAdd, color }) => {
  return (
    <SmallButtonContainer onClick={() => onAdd()} color={color}>
      <img src={"./plus.svg"} alt={"Add Button"} height="70%"></img>
    </SmallButtonContainer>
  );
};

export const BackButton = ({ onBack, color }) => {
  return (
    <SmallButtonContainer onClick={() => onBack()} color={color}>
      <img src={"./back.svg"} alt={"Back Button"} height="85%"></img>
    </SmallButtonContainer>
  );
};

const buttonOnHover = keyframes`
    0% {
      border: 3px solid ${({ color }) => color};
      background-color: ${({ color }) => color};
      color: ${globalColors.Ice};
    }
    100% {
      border: 3px solid ${({ color }) => color};
      background-color: ${globalColors.Ice};
      color: ${({ color }) => color};
    }
`;

const buttonNotHover = keyframes`
    0% {
      border: 3px solid ${({ color }) => color};
      background-color: ${globalColors.Ice};
      color: ${({ color }) => color};
    }
    100% {
      border: 3px solid ${({ color }) => color};
      background-color: ${({ color }) => color};
      color: ${globalColors.Ice};
    }
`;

export const Button = styled.button`
  width: 323.2px;
  height: 64px;
  background-color: ${({ color }) => color};
  border: 3px solid ${({ color }) => color};
  border-radius: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: ${globalColors.Ice};
  margin: ${({ margin }) => margin || "0px"};
  cursor: pointer;

  :hover {
    animation: ${buttonOnHover} 0.5s forwards;
  }

  :not(hover) {
    animation: ${buttonNotHover} 0.5s forwards;
  }

  @media only screen and (max-width: 480px) {
    width: 240px;
    height: 52px;
    font-size: 18px;
  }
`;

const SmallButtonContainer = styled.button`
  width: 70px;
  height: 44px;
  background-color: ${({ color }) => color};
  border: 3px solid ${({ color }) => color};
  border-radius: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: ${globalColors.Ice};
  margin: ${({ margin }) => margin || "0px"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    animation: ${buttonOnHover} 0.5s forwards;
  }

  :not(hover) {
    animation: ${buttonNotHover} 0.5s forwards;
  }

  @media only screen and (max-width: 480px) {
    width: 47.72px;
    height: 30px;
  }
`;

export const ImageButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;

  background-color: #00000000;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  border: 0px;
  cursor: pointer;

  @media only screen and (max-width: 480px) {
    width: 24px;
    height: 24px;
  }
`;
