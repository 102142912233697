import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

.hideOnMobile{
  @media only screen and (max-width: 480px) {
    display: none;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Open Sans';
  src: url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
`;
