import styled from "styled-components";
import { BackButton, AddButton, MainContainer } from "../atoms";
import { H2 } from "../atoms";

export const MainHeader = ({ open, color, title, children, onBack, onAdd }) => {
  const RenderBackButton = () => {
    return <BackButton color={color} onBack={() => onBack()}></BackButton>;
  };

  const RenderAddButton = () => {
    return <AddButton color={color} onAdd={() => onAdd()}></AddButton>;
  };

  return (
    <MainContainer open={open}>
      <HeaderContainer>
        <H2 color={color}>{title}</H2>
        {onBack ? <RenderBackButton></RenderBackButton> : <></>}
        {onAdd ? <RenderAddButton></RenderAddButton> : <></>}
      </HeaderContainer>
      <MainHeaderContentContainer>{children}</MainHeaderContentContainer>
    </MainContainer>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;

  @media only screen and (max-width: 480px) {
    margin-bottom: 16px;
  }
`;

const MainHeaderContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (max-width: 480px) {
  }
`;
