import { RentListCard, PrintRent, MainHeader, RentForm } from "../molecules";
import { useEffect, useState } from "react";
import {
  tryGetRents,
  tryPostRent,
  tryDeleteRent,
  tryPutRent,
} from "../../services/api/requests";
import { validateRentForm } from "../../services/validateForms";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const emptyRent = {
  date: new Date(),
  previousClock: 0,
  currentClock: 0,
  totalBill: 0,
  totalConsumption: 0,
  base: 0,
  water: 0,
  IPTU: 0,
};

export const RentList = ({ colors }) => {
  const Navigate = useNavigate();
  const token = useSelector((state) => state.store.token);
  const [rents, setRents] = useState([]);
  const [openRentList, setOpenRentList] = useState(true);
  const [openPrintRent, setOpenPrintRent] = useState(false);
  const [openAddRent, setOpenAddRent] = useState(false);
  const [openEditRent, setOpenEditRent] = useState(false);
  const [render, setRender] = useState("RentList");
  const [rentToPrint, setRentToPrint] = useState(emptyRent);
  const [rentToEdit, setRentToEdit] = useState(emptyRent);

  useEffect(() => {
    loadRents();
    // eslint-disable-next-line
  }, []);

  const sortList = (list) => {
    return list.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  };

  const loadRents = async () => {
    try {
      const result = await tryGetRents(token);
      const list = [];

      for (const key in result) {
        list.push(result[key]);
      }

      const sortedList = sortList(list);
      setRents(sortedList);
    } catch (error) {
      Navigate("/");
    }
  };

  const onBackPrintRent = () => {
    setOpenPrintRent(false);
    setTimeout(() => {
      setRender("RentList");
      setOpenRentList(true);
    }, 500);
  };

  const onBackAddRent = () => {
    setOpenAddRent(false);
    setTimeout(() => {
      setRender("RentList");
      setOpenRentList(true);
    }, 500);
  };

  const onBackEditRent = () => {
    setOpenEditRent(false);
    setTimeout(() => {
      setRender("RentList");
      setOpenRentList(true);
    }, 500);
  };

  const onAdd = () => {
    setOpenRentList(false);
    setTimeout(() => {
      setRender("AddRent");
      setOpenAddRent(true);
    }, 500);
  };

  const onEdit = (rent) => {
    setRentToEdit(rent);
    setOpenRentList(false);
    setTimeout(() => {
      setRender("EditRent");
      setOpenEditRent(true);
    }, 500);
  };

  const onPrint = (rent) => {
    setOpenRentList(false);
    setTimeout(() => {
      setRentToPrint(rent);
      setRender("PrintRent");
      setOpenPrintRent(true);
    }, 500);
  };

  const onDelete = async (rent) => {
    await tryDeleteRent(rent, token);

    const newList = rents.filter((element) => {
      return element.id !== rent.id;
    });

    setRents(newList);
  };

  const onSubmitAddRent = async (rent) => {
    try {
      await validateRentForm(rent);

      const result = await tryPostRent(rent, token);

      const newList = rents;
      newList.push(result);

      const sortedList = sortList(newList);
      setRents(sortedList);

      onBackAddRent();
    } catch (error) {
      window.alert(error?.message);
    }
  };

  const onSubmitEditRent = async (rent) => {
    try {
      await validateRentForm(rent);

      const result = await tryPutRent(rent, token);

      const index = rents.findIndex((element) => {
        return element.id === result.id;
      });

      const newList = rents;
      newList[index] = result;

      const sortedList = sortList(newList);
      setRents(sortedList);

      onBackEditRent();
    } catch (error) {
      window.alert(error?.message);
    }
  };

  const RentListRender = () => {
    return (
      <MainHeader
        open={openRentList}
        color={colors.Primary}
        onAdd={onAdd}
        title={"Aluguel da Loja"}
      >
        {rents.map((rent) => {
          return (
            <RentListCard
              key={rent.id}
              color={colors.Primary}
              rent={rent}
              onDelete={onDelete}
              onEdit={onEdit}
              onPrint={onPrint}
            ></RentListCard>
          );
        })}
      </MainHeader>
    );
  };

  const AddRentRender = () => {
    return (
      <MainHeader
        open={openAddRent}
        color={colors.Primary}
        onBack={onBackAddRent}
        title={"Adicionar Aluguel"}
      >
        <RentForm
          initalRent={emptyRent}
          color={colors.Primary}
          buttonText={"Adicionar"}
          onSubmit={onSubmitAddRent}
        ></RentForm>
      </MainHeader>
    );
  };

  const EditRentRender = () => {
    return (
      <MainHeader
        open={openEditRent}
        color={colors.Primary}
        onBack={onBackEditRent}
        title={"Editar Aluguel"}
      >
        <RentForm
          initalRent={rentToEdit}
          buttonText={"Editar"}
          color={colors.Primary}
          onSubmit={onSubmitEditRent}
        ></RentForm>
      </MainHeader>
    );
  };

  const PrintRentRender = () => {
    return (
      <MainHeader
        open={openPrintRent}
        color={colors.Primary}
        onBack={onBackPrintRent}
        title={"Imprimir Aluguel"}
      >
        <PrintRent rent={rentToPrint}></PrintRent>
      </MainHeader>
    );
  };

  return (
    <>
      {render === "RentList" ? <RentListRender></RentListRender> : <></>}
      {render === "PrintRent" ? <PrintRentRender></PrintRentRender> : <></>}
      {render === "AddRent" ? <AddRentRender></AddRentRender> : <></>}
      {render === "EditRent" ? <EditRentRender></EditRentRender> : <></>}
    </>
  );
};
