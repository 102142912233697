import { RentTemplate } from "../components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { tryGetUser } from "../services/api/requests";
import { useSelector } from "react-redux";

export const RentScreen = () => {
  const Navigate = useNavigate();
  const token = useSelector((state) => state.store.token);
  const getUser = async () => {
    return await tryGetUser(token);
  };

  useEffect(() => {
    if (!token) {
      Navigate("/");
    }

    try {
      getUser();
    } catch (error) {
      Navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return <RentTemplate></RentTemplate>;
};
