import { LoginForm } from "../molecules/loginForm";
import { useState } from "react";
import { tryLogin } from "../../services/api/requests";
import { useNavigate } from "react-router-dom";
import { validateLoginForm } from "../../services/validateForms";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../../services/store/slice";

export const StartLogin = ({ colors }) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [dataError, setDataError] = useState(false);

  const onSubmit = async (loginData) => {
    try {
      await validateLoginForm(loginData);

      const result = await tryLogin(loginData.cnpj, loginData.password);

      dispatch(
        setUser({ id: result.id, name: result.name, cnpj: result.cnpj })
      );
      dispatch(setToken(result.token));

      setOpen(false);

      setTimeout(() => {
        Navigate("/rent");
      }, 500);
    } catch (error) {
      setDataError(true);
      return window.alert("Usuário ou Senha Inválido");
    }
  };

  return (
    <LoginForm
      open={open}
      colors={colors}
      dataError={dataError}
      onSubmit={onSubmit}
    ></LoginForm>
  );
};
