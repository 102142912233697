import styled from "styled-components";
import { LoginContainer, Button, H1, Input } from "../atoms";
import { useState } from "react";

export const LoginForm = ({ colors, open, dataError, onSubmit }) => {
  const [loginData, setLoginData] = useState({ cnpj: "", password: "" });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  return (
    <LoginContainer open={open}>
      <H1 margin="32px 0px 0px 0px" color={colors.Primary}>
        Login
      </H1>
      <LoginInputContainer>
        <Input
          label="Usuário"
          width="436px"
          value={loginData.cnpj}
          onChange={handleInput}
          color={colors.Primary}
          dataError={dataError}
          name="cnpj"
          type={"text"}
        ></Input>
        <Input
          label="Senha"
          width="436px"
          value={loginData.password}
          onChange={handleInput}
          color={colors.Primary}
          dataError={dataError}
          name="password"
          type={"password"}
        ></Input>
      </LoginInputContainer>
      <Button
        margin="0px 0px 32px 0px"
        onClick={() => onSubmit(loginData)}
        color={colors.Primary}
      >
        Entrar
      </Button>
    </LoginContainer>
  );
};

const LoginInputContainer = styled.div`
  width: 90%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 480px) {
    height: 194px;
  }
`;
