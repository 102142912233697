import styled, { keyframes } from "styled-components";
import { globalColors } from "../../styles";

export const Input = ({
  color,
  label,
  unit,
  value,
  onChange,
  dataError,
  name,
  type,
}) => {
  return (
    <InputContainer>
      <InputLabel>{label}</InputLabel>
      <InputContentContainer dataError={dataError}>
        {unit ? <InputUnit color={color}>{unit}</InputUnit> : <></>}
        <InputInput
          onChange={onChange}
          value={value}
          color={color}
          type={type}
          name={name}
        ></InputInput>
      </InputContentContainer>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  width: 100%;
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 480px) {
    height: 74px;
  }
`;

const InputLabel = styled.label`
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: ${globalColors.Gray};
  text-align: left;
  width: 100%;

  @media only screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const InputUnit = styled.label`
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: ${globalColors.Gray};
  text-align: left;
  margin-left: 12px;

  @media only screen and (max-width: 480px) {
    font-size: 20px;
    margin-left: 8px;
  }
`;

const onErrorAnimation = keyframes`
    0% {
      border: 0.5px solid ${globalColors.Gray};
    }
    100% {
      border: 2px solid ${globalColors.Red};
    }
`;

const noErrorAnimation = keyframes`
    0% {
      border: 2px solid ${globalColors.Red};
    }
    100% {
      border: 0.5px solid ${globalColors.Gray};
    }
`;

const InputContentContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 58px;
  border: 0.5px solid ${globalColors.Gray};
  border-radius: 20px;
  background-color: white;
  filter: drop-shadow(0px 3px 3px ${globalColors.Shadow});
  animation: ${({ dataError }) =>
      dataError === true ? onErrorAnimation : noErrorAnimation}
    0.5s forwards;

  @media only screen and (max-width: 480px) {
    height: 50px;
  }
`;

const InputInput = styled.input`
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: ${({ color }) => color};
  border: 0px;
  margin-left: 12px;
  margin-right: 12px;
  height: 40px;
  width: 100%;
  background-color: #00000000;

  -moz-appearance: textfield;

  :focus {
    outline: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media only screen and (max-width: 480px) {
    font-size: 20px;
    margin-left: 8px;
  }
`;
