import styled, { keyframes } from "styled-components";
import { globalColors } from "../../styles";

const openFading = keyframes`
    0% {
      opacity: 0%
    }
    100% {
      opacity: 100%
    }
`;

const closeFading = keyframes`
    0% {
      opacity: 100%
    }
    100% {
      opacity: 0%
    }
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  width: 500px;
  height: 620px;

  z-index: 10;

  background-color: ${globalColors.Ice};
  border-radius: 20px;
  border: 0.5px solid ${globalColors.Gray};

  filter: drop-shadow(0px 3px 3px ${globalColors.Shadow});
  animation: ${({ open }) => (open === true ? openFading : closeFading)} 0.5s
    forwards;

  @media only screen and (max-width: 480px) {
    width: 88%;
    height: 80%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  width: 960px;
  height: 620px;
  padding: 28px 32px 28px 32px;
  z-index: 10;

  background-color: ${globalColors.Ice};
  border-radius: 20px;
  border: 0px;

  filter: drop-shadow(0px 3px 3px ${globalColors.Shadow});
  animation: ${({ open }) => (open === true ? openFading : closeFading)} 0.5s
    forwards;

  @media only screen and (max-width: 480px) {
    width: 88%;
    height: 88%;
    padding: 12px;
    padding-top: 14px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;

  width: calc(99% - 32px);
  height: 58px;
  padding: 0px 16px;
  margin: 0px 0px 8px 0px;

  z-index: 10;

  background-color: ${globalColors.White};
  border-radius: 20px;
  border: 0.5px solid ${globalColors.Gray};
  animation: ${({ open }) => (open === true ? openFading : closeFading)} 0.5s
    forwards;

  @media only screen and (max-width: 480px) {
    height: 36px;
    width: calc(99% - 16px);
    padding: 0px 8px;
  }
`;
