export const globalColors = {
  Transparent: "#00000000",
  Black: "#000000",
  Ice: "#F9FBFC",
  White: "#FFFFFF",
  Gray: "#9D9FAF",
  Shadow: "#8c8c8c",
  Red: "#FF9FAF",
};

export const purpleColors = {
  Primary: "#322E54",
  Secundary: "#A27CCD",
  Background: "#BBAACC",
};
