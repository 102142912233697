import styled from "styled-components";

export const Image = ({ className, src, width, height, margin }) => {
  return (
    <ImageContainer
      className={className}
      src={src}
      width={width}
      height={height}
      margin={margin}
    ></ImageContainer>
  );
};

const ImageContainer = styled.img`
  width: ${({ width }) => width || "22px"};
  height: ${({ height }) => height || "22px"};
  margin: ${({ margin }) => margin || "0px"};
`;
