import styled from "styled-components";

export const H1 = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-size: 44px;
  font-weight: bold;
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign || "center"};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin || "0px"};

  @media only screen and (max-width: 480px) {
    font-size: 34px;
  }
`;

export const H2 = styled.h2`
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign || "center"};
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin || "0px"};

  @media only screen and (max-width: 480px) {
    font-size: 26px;
  }
`;

export const Label = styled.label`
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  font-weight: normal;
  color: ${({ color }) => color};

  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
