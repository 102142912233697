export const dateString = (date) => {
  const dateFormat = new Date(date);
  const months = [
    "jan",
    "fev",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
  ];
  const month = months[dateFormat.getMonth()];
  const year = dateFormat.getFullYear();

  return month + "/" + year;
};

export const kwhString = (value) => {
  return Math.round(value) + " Kwh";
};

export const moneyString = (value) => {
  const intValue = Math.round(value * 100);
  const stringValue = intValue.toString();
  const money = stringValue.slice(0, stringValue.length - 2);
  const cents = stringValue.slice(stringValue.length - 2, stringValue.length);

  let moneyWithPoints = "";
  if (money.length > 0) {
    for (let i = 0; i < money.length; i++) {
      if ((money.length - 1 - i) % 3 === 0 && money.length - 1 - i !== 0) {
        moneyWithPoints = moneyWithPoints + money[i] + ".";
      } else {
        moneyWithPoints = moneyWithPoints + money[i];
      }
    }
  } else {
    moneyWithPoints = "0";
  }

  return "R$ " + moneyWithPoints + "," + cents;
};
