import { instance } from ".";

export const tryLogin = async (cnpj, password) => {
  try {
    const response = await instance.post("/login", {
      cnpj: cnpj,
      password: password,
    });
    return response.data.result;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const tryGetUser = async (token) => {
  try {
    const auth = { headers: { Authorization: `Bearer ${token}` } };

    const response = await instance.get("/user", auth);
    return response.data.result;
  } catch (error) {
    throw new Error("Erro inesperado");
  }
};

export const tryGetRents = async (token) => {
  try {
    const auth = { headers: { Authorization: `Bearer ${token}` } };

    const response = await instance.get("/rents", auth);
    return response.data.result;
  } catch (error) {
    throw new Error("Erro inesperado");
  }
};

export const tryPostRent = async (rent, token) => {
  try {
    const auth = { headers: { Authorization: `Bearer ${token}` } };

    const response = await instance.post("/rent", { ...rent }, auth);
    return response.data.result;
  } catch (error) {
    throw new Error("Aluguel não criado");
  }
};

export const tryPutRent = async (rent, token) => {
  try {
    const auth = { headers: { Authorization: `Bearer ${token}` } };

    const response = await instance.put(`/rent/${rent.id}`, { ...rent }, auth);
    return response.data.result;
  } catch (error) {
    throw new Error("Aluguel não editado");
  }
};

export const tryDeleteRent = async (rent, token) => {
  try {
    const auth = { headers: { Authorization: `Bearer ${token}` } };

    const response = await instance.delete(`/rent/${rent.id}`, auth);
    return response.data.result;
  } catch (error) {
    throw new Error("Aluguel não deletado");
  }
};
