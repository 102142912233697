import { object, string, date, number } from "yup";

export const validateRentForm = async (rent) => {
  const schema = object({
    date: date().required("Data é Obrigatória"),
    previousClock: number("Relógio Anterior é um número").required(
      "Relógio Anterior é obrigatório"
    ),
    currentClock: number("Relógio Atual é um número").required(
      "Relógio Atual é obrigatório"
    ),
    totalBill: number("Total da Conta é um número").required(
      "Total da Conta é obrigatório"
    ),
    totalConsumption: number("Consumo Total é um número").required(
      "Consumo Total é obrigatório"
    ),
    base: number("Valor Base é um número").required("Valor Base é obrigatório"),
    water: number("Água é um número").required("Água é obrigatório"),
    IPTU: number("IPTU é um número").required("IPTU é obrigatório"),
  });

  console.log(rent);
  await schema.validate(rent);
};

export const validateLoginForm = async (loginData) => {
  const schema = object({
    cnpj: string()
      .required("Usuário é Obrigatório")
      .matches(/^\d{14}$/, "Usuário ou Senha Inválido"),
    password: string().required("Senha é Obrigatória"),
  });

  await schema.validate(loginData);
};
