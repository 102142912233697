import { useEffect, useState } from "react";
import { Button, Input } from "../atoms";
import styled from "styled-components";

export const RentForm = ({ color, buttonText, initalRent, onSubmit }) => {
  const [rent, setRent] = useState(initalRent);

  useEffect(() => {
    setRent(initalRent);
  }, [initalRent]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setRent({
      ...rent,
      [name]: value,
    });
  };

  return (
    <RentFormContainer>
      <RentFormCollumnOnMobile>
        <RentFormInputContainer>
          <Input
            label={"Mês e Ano"}
            color={color}
            value={rent.date}
            onChange={handleInput}
            name="date"
            dataError={false}
            type={"date"}
          ></Input>
        </RentFormInputContainer>
        <RentFormRow>
          <RentFormInputContainer>
            <Input
              label={"Relógio Atual"}
              unit="Kwh"
              color={color}
              value={rent.currentClock}
              onChange={handleInput}
              name="currentClock"
              dataError={false}
              type={"number"}
            ></Input>
          </RentFormInputContainer>
          <RentFormInputContainer>
            <Input
              label={"Relógio Anterior"}
              unit="Kwh"
              color={color}
              value={rent.previousClock}
              onChange={handleInput}
              name="previousClock"
              dataError={false}
              type={"number"}
            ></Input>
          </RentFormInputContainer>
        </RentFormRow>
      </RentFormCollumnOnMobile>
      <RentFormRow>
        <RentFormInputContainer>
          <Input
            label={"Valor da Conta"}
            unit="R$"
            color={color}
            value={rent.totalBill}
            onChange={handleInput}
            name="totalBill"
            dataError={false}
            type={"number"}
          ></Input>
        </RentFormInputContainer>
        <RentFormInputContainer>
          <Input
            label={"Consumo Total"}
            unit="Kwh"
            color={color}
            value={rent.totalConsumption}
            onChange={handleInput}
            name="totalConsumption"
            dataError={false}
            type={"number"}
          ></Input>
        </RentFormInputContainer>
      </RentFormRow>
      <RentFormCollumnOnMobile>
        <RentFormInputContainer>
          <Input
            label={"Valor Base"}
            unit="R$"
            color={color}
            value={rent.base}
            onChange={handleInput}
            name="base"
            dataError={false}
            type={"number"}
          ></Input>
        </RentFormInputContainer>
        <RentFormRow>
          <RentFormInputContainer>
            <Input
              label={"IPTU"}
              unit="R$"
              color={color}
              value={rent.water}
              onChange={handleInput}
              name="water"
              dataError={false}
              type={"number"}
            ></Input>
          </RentFormInputContainer>
          <RentFormInputContainer>
            <Input
              label={"Água"}
              unit="R$"
              color={color}
              value={rent.IPTU}
              onChange={handleInput}
              name="IPTU"
              dataError={false}
              type={"number"}
            ></Input>
          </RentFormInputContainer>
        </RentFormRow>
      </RentFormCollumnOnMobile>
      <Button
        color={color}
        margin="32px 0px 0px 0px"
        onClick={() => onSubmit(rent)}
      >
        {buttonText}
      </Button>
    </RentFormContainer>
  );
};

const RentFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const RentFormInputContainer = styled.div`
  width: 240px;

  @media only screen and (max-width: 480px) {
    width: 150px;
  }
`;

const RentFormRow = styled.div`
  width: 540px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-top: 8px;
  }
`;

const RentFormCollumnOnMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }
`;
