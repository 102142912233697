import styled from "styled-components";
import { RentList } from "../organisms";
import { purpleColors } from "../../styles";

export const RentTemplate = ({ open, rents }) => {
  return (
    <RentTemplateContainer>
      <RentList colors={purpleColors}></RentList>
    </RentTemplateContainer>
  );
};

const RentTemplateContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("./purpleBackground.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
