import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "./styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginScreen, RentScreen } from "./screens";
import { store } from "./services/store";
import { Provider } from "react-redux";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyle></GlobalStyle>
          <Router>
            <Routes>
              <Route path="/" element={<LoginScreen></LoginScreen>}></Route>
              <Route path="/rent" element={<RentScreen></RentScreen>}></Route>
            </Routes>
          </Router>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
